import { DataService } from "../axios";


async function getAllSubscriptionDetails(membership_status=null){
    return await DataService.get(`guest-user/account/?membership_status=${membership_status}`)
}

async function updateSubscriptionStatus(id,data){
    return await DataService.put(`guest-user/change-membership-progress/${id}/`,data)
}


export{
    getAllSubscriptionDetails,
    updateSubscriptionStatus
}