import {  getAllSubscriptionDetails } from "@/services/apis/subscription";

const subscription = {
    state: {
        selectedTab: 0,
        loading: false,
        subscriptionData:[]
    },
    mutations: {
        SELECT_TAB(state, data) {
            state.selectedTab = data
        },
        TOGGLE_LOADING(state){
            state.loading = !state.loading
        },
        SUBSCRIPTION_DATA(state,data){
            state.subscriptionData =  data
        },
        REMOVE_SUBSCRIPTION_DATA(state,id){
            state.subscriptionData = state.subscriptionData.filter(item=>item.id!==id)
        },
        CLEAR_SUBSCRIPTION_DATA(state){
            state.subscriptionData = []
        }

    },
    actions: {
        selectSubscriptionTab({ commit }, data) {
            commit('CLEAR_SUBSCRIPTION_DATA')
            commit('SELECT_TAB', data)
            commit('TOGGLE_LOADING')
            getAllSubscriptionDetails(data).then((res) => {
                commit('SUBSCRIPTION_DATA',res.data)
            }).finally(()=>{
                commit('TOGGLE_LOADING')
            });
             
        },
        removeSubscriptionByID({commit},id){
            commit('REMOVE_SUBSCRIPTION_DATA', id)
        },
        refreshSubscription({commit,state}){
            commit('TOGGLE_LOADING')
            getAllSubscriptionDetails(state.selectedTab).then((res) => {
                commit('SUBSCRIPTION_DATA',res.data)
            }).finally(()=>{
                commit('TOGGLE_LOADING')
            });
        }
        
    }
}
export default subscription