<template>
<div class="logout-btn">
   <button @click="logout">
       <CIcon icon="cil-lock-locked" /> Logout 
   </button>
  
</div>

</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods:{
    logout(){
       localStorage.removeItem('token');
       this.$router.push({name:"Login"});
    }
  }
}
</script>
<style scoped>
.logout-btn button{
  background-color: #fff;
  border: none;
  outline: none;
  color: #000;
  font-weight: 500;
  padding:4px 8px;
  border-radius: 8px;
 
}
</style>
