<template>
  <CFooter style="background-color:#000;font-size:0.8rem">
    <div>
      <a href="https://plentyofthings.com" style="text-decoration:none;color:#C8A791;" target="_blank">Plenty</a>
      <span class="ms-1" style="color:white" 
        >&copy; {{ new Date().getFullYear() }} Plentyofthings.com</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" style="color:white" target="_blank">Powered by</span>
      <a href="https://mvp-apps.ae" style="text-decoration:none;color:#C8A791;">MVP-APPS.AE</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
