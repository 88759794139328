import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/subscription',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/booking',
        name: 'Booking',
        redirect: '/booking/view',
      },
      {
        path: '/booking/view',
        name: 'Booking Management',
        component: () => import('@/views/booking/Manage.vue'),
      },
      {
        path: '/subscription',
        name: 'Subscription',
        redirect: '/subscription/manage',
      },
      {
        path: '/subscription/manage',
        name: 'Subscription Management',
        component: () => import('@/views/subscription/Manage.vue'),
      },
      {
        path: '/subscription/add-member',
        name: 'Add Memeber',
        component: () => import('@/views/subscription/AddMember.vue'),
      },   
      {
        path: '/subscription/card',
        name: 'View-Card',
        component: () => import('@/views/subscription/ViewCard.vue'),
      }, 
      {
        path: '/interest',
        name: 'Interest',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/interest'),
      },
      
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory('http://localhost:8080/'),
  routes,
 
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  let token = JSON.parse(localStorage.getItem('token'))
  let flag = token ? true:false;

  if (to.name !== 'Login' && !flag) {
    next({
      name: 'Login',
    });
  }
  else {
    next();
  }
})



export default router
